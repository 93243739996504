import {
  getAPY,
  getColors,
  getCombined,
  getCombinedAssets,
  getCombinedParents,
  getCount,
  getIsInDesktopWallet,
  getIsInMobileWallet,
  getIsInWeb3Wallet,
  getIsNew,
  getIsOld,
  getIsUnavailable,
  getLedger,
  getLink,
  getMarketcap,
  getName,
  getNetwork,
  getNew,
  getPrice,
  getTicker,
  getTrezor,
} from 'src/js/assets/utils'

function getCombinedData(combinedAssets, key) {
  if (!Array.isArray(combinedAssets)) return null

  const hasKeyInCombinedAssets = combinedAssets.some((asset) => asset[key])

  if (!hasKeyInCombinedAssets) return null

  const combinedData = combinedAssets.find((asset) => asset[key])
  return combinedData[key]
}

const processAssetData = (dataObjects, asset, withApy, withExchange) => {
  const apy = getAPY(asset.ticker, withApy)
  const exchange = withExchange?.has(asset?.ticker)
  const exchangeStatus = exchange ? 'Available' : 'Unavailable'
  const name = getName(asset)
  const { network, networkColors } = getNetwork(dataObjects, asset)
  const isNew = getIsNew(dataObjects.newAssetsData, asset)
  const old = getIsOld(asset)
  const ticker = getTicker(asset)
  const ledger = getLedger(asset.baseAssetName)
  const trezor = getTrezor(dataObjects.assetsData, asset.name)
  const desktopWallet = getIsInDesktopWallet(asset)
  const mobileWallet = getIsInMobileWallet(asset)
  const web3Wallet = getIsInWeb3Wallet(asset)
  const unavailable = getIsUnavailable(dataObjects.assetsData, asset)

  return {
    assetName: asset.name,
    baseAssetName: asset.baseAssetName,
    apy,
    exchange,
    exchangeStatus,
    ledger,
    name,
    network,
    networkColors,
    new: isNew,
    old,
    ticker,
    trezor,
    desktopWallet,
    mobileWallet,
    web3Wallet,
    unavailable,
  }
}

const prepareAssetList = (
  dataObjects,
  withApy,
  withExchange,
  withPrice,
  withCurrentPrice,
  combined = false
) => {
  const assetNames = Object.keys(dataObjects.assetsData)

  let assets = assetNames
  if (combined) assets = getCombined(dataObjects.assetsData, assetNames)

  const unavailableAssets = []

  // Build asset list.
  const assetsArray = assets.map((key) => {
    const asset = dataObjects.assetsData[key]
    const marketcap = getMarketcap(asset.ticker, withPrice)
    const price = getPrice(asset.ticker, withPrice, withCurrentPrice)
    const link = getLink(asset.ticker)
    const colors = getColors(asset)

    const processAsset = (assetKey) => {
      return processAssetData(dataObjects, assetKey, withApy, withExchange)
    }
    const isCombinedAsset = getCombinedParents(dataObjects.assetsData, unavailableAssets)
    const isCombined = Boolean(combined && isCombinedAsset.includes(key))

    const combinedAssets = isCombined
      ? [
          ...new Set(
            getCombinedAssets(dataObjects.assetsData, unavailableAssets, asset).map((ckey) => {
              return { ...processAsset(ckey), link }
            })
          ),
        ]
      : null

    const apy = getCombinedData(combinedAssets, 'apy')
    const ledger = getCombinedData(combinedAssets, 'ledger')
    const trezor = getCombinedData(combinedAssets, 'trezor')

    const commonProps = { colors, marketcap, price }
    const isCombinedProps = { isCombined, apy, ledger, trezor, ...commonProps }
    const notCombinedProps = { link, ...commonProps }

    return {
      ...processAsset(asset),
      ...(combinedAssets && { combinedAssets }),
      ...(isCombined ? isCombinedProps : notCombinedProps),
    }
  })

  // Filter out unavailable assets and without icon, and return.
  const filteredAssets = assetsArray.filter((asset) => !asset.unavailable)
  // Assets cont.
  const filteredAssetsCount = getCount(filteredAssets)
  // Get new assets.
  const newAssets = getNew(filteredAssets)

  return {
    assets: filteredAssets,
    count: filteredAssetsCount,
    new: newAssets,
  }
}

export default prepareAssetList

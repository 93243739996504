import classNames from 'classnames'
import { get } from 'lodash'
import React, { useState } from 'react'

import AssetItemIcon from 'components/assets/shared/AssetItemIcon'
import AssetItemLabel from 'components/assets/shared/AssetItemLabel'
import { useDimensions } from 'js/utils/hooks'
import { LEDGER_WALLET_ENABLED } from 'src/constants'

import 'components/assets/shared/scss/AssetItem.scss'

// Helpers:
const hasContentCheck = (column, data) => {
  const columnMobileWalletData = column === 'mobile' && data.mobileWallet
  const columnDesktopWalletData = column === 'desktop' && data.desktopWallet
  const columnWeb3WalletData = column === 'web3' && data.web3Wallet
  const columnTrezorData = column === 'trezor' && data.trezor
  const columnLedgerData = column === 'ledger' && LEDGER_WALLET_ENABLED && data.ledger
  const columnExchangeData = column === 'exchange' && data.exchange

  return (
    columnMobileWalletData ||
    columnDesktopWalletData ||
    columnWeb3WalletData ||
    columnTrezorData ||
    columnLedgerData ||
    columnExchangeData
  )
}

const cellColor = (column, data) => {
  switch (column) {
    case 'trezor':
      // data.trezor is a string with value: "Supported" or "Supported on ...".
      // Orange for partial support. Full support in green. We don't show a
      // checkmark if not supported.
      return data.trezor !== 'Supported' ? 'orange' : 'green'
    case 'ledger':
      return data.ledger && 'green'
    case 'exchange':
      return get(data, ['functionality', column, 'color'], data.exchange ? 'green' : 'orange')
    default:
      return 'green'
  }
}

const cellTooltipText = (type, column, data) => {
  switch (type) {
    case 'platform':
      // We show "Supported" tooltip for full support. "Supported on ..." for
      // those with partial support. And nothing for those not supported.
      // ref: src/js/assets/utils/getHardware.js
      if (column === 'trezor') {
        return data.trezor
      }
      if (column === 'ledger') {
        return data.ledger
      }
      break
    case 'functionality':
      return get(data, ['functionality', column, 'tooltipText'], data.exchangeStatus)
    default:
      return 'Available'
  }
}

function Cell({ index, data, columnName, columnType, noTooltip }) {
  const hasContent = hasContentCheck(columnName, data)
  if (!hasContent) return <AssetItemIcon />

  const symbol = data.ticker
  const tooltipText = cellTooltipText(columnType, columnName, data)
  const color = cellColor(columnName, data)

  const props = { index, symbol, columnName, color, ...(noTooltip ? {} : tooltipText) }

  return <AssetItemIcon {...props} />
}

function Item({
  index,
  data,
  items,
  isMobile,
  hasDetails,
  hasSection,
  hasTooltip,
  isCombinedItem = false,
  isLastItem = false,
  theme,
  dropdownOpen,
  onClick = () => {},
}) {
  return (
    <div
      className={classNames(
        'x__asset-item-label',
        'x__asset-table__item',
        `x__asset-table__item--${theme}`,
        { 'x__asset-table__item--sections': hasSection }
      )}
      onClick={onClick}
    >
      <div className="x__group x__group--default">
        <span className="x__cell x__cell--index">{!isCombinedItem ? index + 1 : ' '}</span>
        <div
          className={classNames('x__cell x__cell--asset', {
            'x__cell--asset-combined': isCombinedItem,
            'x__cell--asset-combined--last': isCombinedItem && isLastItem,
          })}
        >
          <AssetItemLabel data={data} theme={theme} showTransparentNetworkIcon={!!isCombinedItem} />
          {!isCombinedItem && data.combinedAssets ? (
            <i className={classNames('x__picker', { 'x__picker--active': dropdownOpen })}>
              <svg width="14" height="8" viewBox="0 0 14 8" fill="none">
                <path d="M13 1L7 7L1 0.999999" />
              </svg>
            </i>
          ) : null}
        </div>
        {isMobile || data.apy ? (
          <div className="x__cell x__cell--apy">
            <span className="x__apy">{data.apy}%</span>
          </div>
        ) : null}
      </div>
      <div className="x__group x__group--columns">
        {items.map(
          ({ name, type }, i) =>
            !!name && (
              <div key={i} className={`x__cell x__cell--${name}`}>
                <Cell
                  index={index}
                  data={data}
                  columnName={name}
                  columnType={type}
                  noTooltip={!hasTooltip}
                />
              </div>
            )
        )}
        {hasDetails ? (
          <div className="x__cell x__cell--details">
            {data.exchange && <span>{data.exchangeStatus || ''}</span>}
          </div>
        ) : null}
      </div>
    </div>
  )
}

function AssetItem({ index, data, columns, noTooltip = true, theme = 'light' }) {
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const { isMobile } = useDimensions()

  const hasSection = columns.some((col) => col.section)
  const items = hasSection ? columns.map((col) => col.headings).flat() : columns
  const hasDetails = !!items.find((item) => item.name === 'details')
  const filteredItems = items.filter((item) => item.name !== 'details')

  const commonProps = {
    index,
    isMobile,
    items: filteredItems,
    hasDetails,
    hasSection,
    hasTooltip: !noTooltip,
    theme,
  }

  if (!items.length) return null

  return (
    <>
      <Item
        {...commonProps}
        data={data}
        dropdownOpen={dropdownOpen}
        onClick={() => setDropdownOpen(!dropdownOpen)}
      />
      {dropdownOpen
        ? data.combinedAssets &&
          data.combinedAssets.map((asset, i) => (
            <Item
              {...commonProps}
              key={`${asset.ticker}--${i}`}
              data={asset}
              isCombinedItem
              isLastItem={i === data.combinedAssets.length - 1}
            />
          ))
        : null}
    </>
  )
}

export default AssetItem

import React from 'react'

import PageHead from 'components/head/PageHead'
import PageWrapper from 'components/layout/page-wrapper'
import { AssetsSection, HeaderSection } from 'components/pages/assets'
import defaultData from 'data/pages/Contents/assets.json'

import 'static/assets/scss/styles.scss'

function StatusPage({ location, pageData = defaultData }) {
  const { content } = pageData
  const { header, assets } = content

  return (
    <PageWrapper className="x__assets-page" headerProps={{ location, trackerPage: 'assets' }}>
      <HeaderSection data={header} />
      <AssetsSection data={assets} />
    </PageWrapper>
  )
}

export default StatusPage

export function Head() {
  return <PageHead page="assets" nocache />
}
